$gray-dark: #282a36; // Background
$gray: #747683; // Current Line & Selection
$gray-light: #f8f8f2; // Foreground
$blue: #bfc6d8; // Comment
$cyan: #8be9fd;
$green: #50fa7b;
$orange: #ff8000;
$pink: #ff79c6;
$purple: #bd93f9;
$purple-dark: #49178f;
$red: #ff5555;
$yellow: #f1fa8c;
$white: #fefefe; // Foreground

$ipad-width: 768px; // iPad portrait
$bigphone-width: 414px; // iPhone 8 plus
$phone-width: 375px; // iPhone 8
$smallphone-width: 320px; // iPhone SE

$mono-font-stack: "SFMono-Regular",
Consolas,
"Liberation Mono",
Menlo,
Courier,
monospace;

