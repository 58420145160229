:root {
  --icon-size: 150px;
}

// Alter icon size for different devices

@media screen and (max-width: $ipad-width) {
  :root {
    --icon-size: 100px;
  }
}

.ios-icon {
  height: var(--icon-size);
  width: var(--icon-size);
  border-radius: 20%;
}

.app-icon-border {
  box-sizing: border-box;
  border: 2px Solid rgba(220, 220, 220, 1);
}

/* App Index */

.app-index {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: var(--icon-size) 1fr;
  grid-gap: 20px;
  align-items: center;
}

.app-index-container a {
  text-decoration: none;
}

@media (hover: hover) {
  .app-index-container a:hover {
    text-decoration: underline;
  }
}

.app-index p {
    margin: 0px;
}

.app-index a {
  text-decoration: none;
}

.app-index-title {
  font-size: 150%;
  font-weight: normal;
}

.app-index-desc {
  font-weight: lighter;
}



// Big phone

@media screen and (max-width: $bigphone-width) {
  .app-index {
    grid-template-columns: 1fr;
  }

  .app-index img {
    margin-left: auto;
    margin-right: auto;
  }

  p {
    text-align: center;
  }

  .app-index-title {
    font-size: 100%;
    font-weight: normal;
  }

  .app-index-desc {
    font-size: 90%;
    font-weight: lighter;
  }
}