@import "defines";
@import "apps";
@import "work";
@import "contact";
@import "footer";

html {
  background-color: #fefefe;
  color: #333333;
  font-weight: lighter;
  font-family: sans-serif;
  font-size: 120%;
  line-height: 1.5;
    margin-left: 30px;
    margin-right: 30px;
}

body {
  margin: auto;
  max-width: 800px;
}

@media screen and (max-width: $ipad-width) {
  html {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 110%;
  }
}

@media screen and (max-width: $phone-width) {
  html {
    margin-left: 18px;
    margin-right: 18px;
  }
}

@media screen and (max-width: $smallphone-width) {
  html {
    margin-left: 10px;
    margin-right: 10px;
  }
}

nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
}

nav a {
  padding-right: 8px;
  padding-left: 8px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  line-height: 2em;
  text-align: center;
}

.site-title {
  text-align: center;
  font-size: 300%;
}

@media screen and (max-width: $ipad-width) {
  .site-title {
        font-size: 300%;
    }
}

@media screen and (max-width: $bigphone-width) {
  .site-title {
        font-size: 200%;
    }
}

.current-nav-item {
  text-decoration: underline;
}

.centered-content {
  margin: 0 auto;
}

a {
  color: #333333;
}

h1 {
    font-size: 170%;
    text-align: center;
}

@media screen and (max-width: $ipad-width) {
  h1 {
        font-size: 150%;
    }
}

.store-badge {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.small-print {
  font-size: 80%;
  font-style: italic;
}