:root {
  --icon-size: 150px;
}

// @media screen and (max-width: $ipad-width) {
//   :root {
//     --icon-size: 100px;
//   }
// }

.app-grid {
  display: grid;
  grid-gap: 20px;
  margin: auto;
  grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
}

.app-grid a {
  text-decoration: none;
  font-weight: lighter;
}

.app-grid a:hover {
  text-decoration: underline;
}

.app-grid-app {
  display: grid;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  text-align: center;
  justify-items: center;
  justify-content: center;
}