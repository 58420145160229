button, input, textarea {
	font-family : inherit;
	font-size: 100%;
	font-weight: lighter;
}

label {
	font-weight: normal;
}

.text-field {
	width: 100%;
	height: 2.5em;
}

.message-box {
	width: 100%;
	height: 200px;
	border-color: lightgrey;
}